import AnswerList from "components/AnswerList";

const answers = {
  sixth: [
    {
      id: 1,
      title: "Аудиторское заключение за 2024 год",
      link: "/shareholders/Аудиторское заключение за 2024 год.pdf",
    }, 
    {
      id: 2,
      title: "Годовой отчёт ОАО Беларускабель за 2024 год",
      link: "/shareholders/Годовой отчёт ОАО Беларускабель за 2024 год.pdf",
    },       
  ],
  first: [
    {
      id: 1,
      title: "Аудиторское заключение ОАО Беларускабель",
      link: "/shareholders/Аудиторское заключение ОАО Беларускабель.pdf",
    },
    {
      id: 2,
      title:
        "Информация о выплате дивидендов по акциям ОАО Беларускабель за 2023",
      link:
        "/shareholders/Информация о выплате дивидендов по акциям ОАО Беларускабель за 2023.pdf",
    },
    {
      id: 3,
      title:
        "Бухгалтерский баланс ОАО Беларускабель по состоянию на 31.12.2023",
      link:
        "/shareholders/Бухгалтерский баланс ОАО Беларускабель по состоянию на 31.12.2023.pdf",
    },
    {
      id: 4,
      title:
        "Отчет о движении денежных средств ОАО Беларускабель по состоянию на 31.12.2023",
      link:
        "/shareholders/Отчет о движении денежных средств ОАО Беларускабель по состоянию на 31.12.2023.pdf",
    },
    {
      id: 5,
      title:
        "Отчет о прибылях и убытках  ОАО Беларускабель по состоянию на 31.12.2023",
      link:
        "/shareholders/Отчет о прибылях и убытках  ОАО Беларускабель по состоянию на 31.12.2023.pdf",
    },
    {
      id: 6,
      title:
        "Отчет об изменении собственного капитала ОАО Беларускабель по состоянию на 31.12.2023",
      link:
        "/shareholders/Отчет об изменении собственного капитала ОАО Беларускабель по состоянию на 31.12.2023.pdf",
    },
    {
      id: 7,
      title: "Отчет эмитента на 01.01.2024",
      link: "/shareholders/Отчет эмитента на 01.01.2024.xlsx",
    },    
  ],
  second: [
    {
      id: 1,
      title: "Аудиторское заключение",
      link: "/shareholders/Аудиторское заключение c 01.01.2022-31.12.pdf",
    },
    {
      id: 2,
      title: "Годовой отчёт за 2022г. ОАО Беларускабель",
      link: "/shareholders/Годовой отчёт за 2022г. ОАО Беларускабель.xlsx",
    },
  ],
  third: [
    {
      id: 1,
      title: "Аудиторское заключение за период с 01.01.2021-31.12",
      link:
        "/shareholders/Аудиторское заключение за период с 01.01.2021-31.12.pdf",
    },
    {
      id: 2,
      title: "Бухгалт.баланс на 31 декабря 2021 год",
      link: "/shareholders/Бухгалт.баланс на 31 декабря 2021 год.pdf",
    },
    {
      id: 3,
      title: "Годовой отчет ОАО Беларускабель за 2021 год",
      link: "/shareholders/Годовой отчет ОАО Беларускабель за 2021 год.pdf",
    },
    {
      id: 4,
      title: "Дивиденды ОАО Беларускабель за 2021 год",
      link: "/shareholders/Дивиденды ОАО Беларускабель за 2021 год.pdf",
    },
    {
      id: 5,
      title: "Отчёт о движ. денежных средств на 31 декабря 2021 год",
      link:
        "/shareholders/Отчёт о движ. денежных средств на 31 декабря 2021 год.pdf",
    },
  ],
  fourth: [
    {
      id: 1,
      title: "Аудит.закл. за период с 01.01.20-31.12.20",
      link: "/shareholders/Аудит.закл. за период с 01.01.20-31.12.20.pdf",
    },
    {
      id: 2,
      title: "Бухгалтерский баланс на 31.12.20",
      link: "/shareholders/Бухгалтерский баланс на 31.12.20.pdf",
    },
    {
      id: 3,
      title: "Раскрытие годового отчёта",
      link: "/shareholders/Раскрытие годового отчёта.xlsx",
    },
  ],
  fifth: [
    {
      id: 1,
      title: "Аудит.заключение 2019",
      link: "/shareholders/Аудит.заключение 2019.pdf",
    },
    {
      id: 2,
      title: "Бух.баланс на 31.12.19",
      link: "/shareholders/Бух.баланс на 31.12.19.pdf",
    },
    {
      id: 3,
      title: "Инф. об ОАО Беларускабель на 31.12.19",
      link: "/shareholders/Инф. об ОАО Беларускабель на 31.12.19.pdf",
    },
    {
      id: 4,
      title: "ОАО Беларускабель, отч.о движ. ден.ср-в на 31.12.19",
      link:
        "/shareholders/ОАО Беларускабель, отч.о движ. ден.ср-в на 31.12.19.pdf",
    },
    {
      id: 5,
      title: "ОАО беларускабель, отчёт об измен.собств.кап. на 31.12.19",
      link:
        "/shareholders/ОАО беларускабель, отчёт об измен.собств.кап. на 31.12.19.pdf",
    },
    {
      id: 6,
      title: "Отчёт о приб. и убыт. ОАО Беларускабель, на 31.12.2019",
      link:
        "/shareholders/Отчёт о приб. и убыт. ОАО Беларускабель, на 31.12.2019.pdf",
    },
  ],
};

export const config = [
  {
    id: 6,
    question: "Информация об ОАО Беларускабель на 01.01.2025. Годовой отчет",
    answer: <AnswerList config={answers.sixth} />,
  },  
  {
    id: 1,
    question: "Информация об ОАО Беларускабель на 01.01.2024. Годовой отчет",
    answer: <AnswerList config={answers.first} />,
  },
  {
    id: 2,
    question: "Информация об ОАО Беларускабель на 01.01.2023. Годовой отчет",
    answer: <AnswerList config={answers.second} />,
  },
  {
    id: 3,
    question: "Информация об ОАО Беларускабель на 01.01.2022. Годовой отчет",
    answer: <AnswerList config={answers.third} />,
  },
  {
    id: 4,
    question: "Информация об ОАО Беларускабель на 01.01.2021. Годовой отчет",
    answer: <AnswerList config={answers.fourth} />,
  },
  {
    id: 5,
    question: "Информация об ОАО Беларускабель на 01.01.2020. Годовой отчет",
    answer: <AnswerList config={answers.fifth} />,
  },
];
